<template>
  <b-row>
    <b-col
      cols="12"
      class="text-center mb-2"
    >
      <b-button
        v-if="idServiceStatuses === 3"
        variant="info"
        class="mr-1"
        :href="downloadURL"
        target="_blank"
      >
        <FeatherIcon icon="PrinterIcon" />
        Yazdır
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  name: 'ActionButtons',
  components: {
    BRow,
    BCol,
    BButton,
  },
  props: {
    id: Number,
    idServiceStatuses: Number,
  },
  data() {
    return {
      submitStatus: false,
      revised: false,
      revised_note: null,
    }
  },
  computed: {
    downloadURL() {
      const urlParams = [
        `&id=${this.id}`,
      ].join('&')
      const url = `${this.$store.state.app.baseURL}/exports/pdf/services?token=${localStorage.getItem('downloadToken')}${urlParams}`
      return url
    },
    userApprovalStatus() {
      return JSON.parse(localStorage.getItem('userData')).approval
    },
    formData() {
      return this.$store.getters['services/getService']
    },
    saveStatus() {
      return this.$store.getters['services/getServiceSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.getData()
    },
  },
  methods: {
    getData() {
      if (this.submitStatus) {
        this.$store.dispatch('services/serviceView', this.$route.params.id)
        this.submitStatus = false
      }
    },
    approve() {
      this.submitStatus = true
      this.$store.dispatch('services/serviceApprove', {
        id: this.formData.id,
      })
    },
    denied() {
      this.submitStatus = true
      this.$store.dispatch('services/serviceDenied', {
        id: this.formData.id,
      })
    },
    sendRevised() {
      this.submitStatus = true
      this.$store.dispatch('services/serviceRevised', {
        id: this.formData.id,
        revised_note: this.revised_note,
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style scoped></style>
