<template>
  <div>
    <action-buttons
      :id="formData.id"
      :id-service-statuses="formData.id_service_statuses"
    />
    <b-row>
      <b-col
        v-if="formData.id_service_statuses === 1 && formData.revised_note"
        cols="12"
      >
        <b-alert
          show
          variant="info"
        >
          <div class="alert-body text-center">
            <FeatherIcon
              icon="MailIcon"
              size="24"
            />
            <p>{{ formData.revised_note }}</p>
          </div>
        </b-alert>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title>Makine Kartı</b-card-title>
          <b-card-text>
            <machine-card />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card>
          <b-card-title>Servis Bakım</b-card-title>
          <b-card-sub-title class="mb-1">
            No: {{ formData.service_no }}
          </b-card-sub-title>
          <b-card-text>
            <service-card
              v-if="formData.formType === '1'"
              :service-data="formData"
              :service-history="history"
              :form-type="formData.formType"
            />
            <service-card2
              v-if="formData.formType === '2'"
              :service-data="formData"
              :form-type="formData.formType"
            />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        v-if="chartData.concentration"
        cols="12"
        md="4"
      >
        <b-card title="Konsantrasyon">
          <line-chart
            :data-labels="chartData.concentration.categories"
            :data-series="chartData.concentration.series"
            height="300"
          />
        </b-card>
      </b-col>
      <b-col
        v-if="chartData.ph"
        cols="12"
        md="4"
      >
        <b-card title="pH">
          <line-chart
            :data-labels="chartData.ph.categories"
            :data-series="chartData.ph.series"
            height="300"
          />
        </b-card>
      </b-col>
      <b-col
        v-if="chartData.conductivity"
        cols="12"
        md="4"
      >
        <b-card title="İletkenlik">
          <line-chart
            :data-labels="chartData.conductivity.categories"
            :data-series="chartData.conductivity.series"
            height="300"
          />
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card class="text-center">
          <div class="text-muted">
            <FeatherIcon icon="CheckIcon" /> Servis formu {{ moment(formData.created).format('DD.MM.YYYY HH:mm') }} tarihinde {{ formData.username }} tarafından oluşturuldu.
          </div>
          <div
            v-if="formData.id_service_statuses === 3"
            class="text-muted"
          >
            <FeatherIcon icon="CheckIcon" /> Servis formu {{ moment(formData.approved).format('DD.MM.YYYY HH:mm') }} tarihinde {{ formData.user_approval }} tarafından onaylandı.
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardSubTitle, BCardText, BAlert,
} from 'bootstrap-vue'
import MachineCard from '@/views/App/Services/Components/MachineCard.vue'
import ServiceCard from '@/views/App/Services/Components/ServiceCard.vue'
import ServiceCard2 from '@/views/App/Services/Components/ServiceCard2.vue'
import LineChart from '@/views/App/Services/Components/LineChart.vue'
import ActionButtons from '@/views/App/Services/Components/ActionButtons.vue'

export default {
  name: 'View',
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BAlert,
    MachineCard,
    ServiceCard,
    ServiceCard2,
    LineChart,
    ActionButtons,
  },
  data() {
    return {
      formData: {
        id: null,
        related_person: null,
        related_phone: null,
        notes: null,
        analysed: null,
        general_situation: null,
        refractometer: null,
        concentration: null,
        ph: null,
        conductivity: null,
        bacteria: null,
        mildew: null,
        revised_note: null,
        approved: null,
        id_material_types: null,
        id_used_oils: null,
        id_appearances: null,
        id_foreign_oil_ratios: null,
        id_service_statuses: null,
        id_machines: null,
        id_customers: null,
        id_customer_address: null,
      },
    }
  },
  computed: {
    service() {
      return this.$store.getters['services/getService']
    },
    history() {
      return this.$store.getters['services/getServiceHistory']
    },
    chartData() {
      return this.$store.getters['services/getServiceChart']
    },
  },
  watch: {
    service(data) {
      if (data.id) {
        this.setFormData(data)
        this.getCustomer(data.id_customers)
        this.getCustomerAddress(data.id_customer_address)
        this.getMachine(data.id_machines)
      }
    },
  },
  created() {
    this.getService()
  },
  methods: {
    getService() {
      this.$store.dispatch('services/serviceView', this.$route.params.id)
    },
    getCustomer(id) {
      this.$store.dispatch('customers/customerView', id)
    },
    getCustomerAddress(id) {
      this.$store.dispatch('customerAddress/customerAddressView', id)
    },
    getMachine(id) {
      this.$store.dispatch('machines/machineView', id)
    },
    getServiceHistory() {
      this.$store.dispatch('services/serviceHistory', {
        id: this.formData.id,
        id_machines: this.formData.id_machines,
        id_customers: this.formData.id_customers,
      })
    },
    setFormData(data) {
      const serviceData = data
      serviceData.id = Number(serviceData.id)
      serviceData.id_service_statuses = Number(serviceData.id_service_statuses)
      serviceData.general_situation = Number(serviceData.general_situation)
      this.formData = serviceData
      this.getServiceHistory()
    },
  },
}
</script>
